import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1125.000000 1125.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1125.000000) scale(0.100000,-0.100000)">
<path d="M0 5625 l0 -5625 5625 0 5625 0 0 5625 0 5625 -5625 0 -5625 0 0
-5625z m5961 2546 c197 -42 369 -111 534 -213 92 -58 261 -193 304 -244 14
-16 46 -52 71 -81 112 -123 270 -399 325 -568 31 -96 51 -223 58 -365 6 -125
-7 -121 -42 13 -75 277 -129 407 -232 557 -220 321 -566 544 -985 632 -95 20
-138 23 -334 23 -251 0 -311 -8 -505 -72 -458 -149 -796 -496 -959 -985 -63
-191 -80 -301 -73 -501 6 -187 16 -236 93 -475 31 -96 53 -177 49 -180 -24
-23 -140 191 -195 358 -55 169 -72 277 -72 450 0 353 82 663 246 927 80 129
123 183 241 299 252 248 554 385 990 448 17 2 111 3 210 1 132 -3 206 -9 276
-24z m-2229 -951 c7 -11 0 -42 -25 -105 -47 -121 -57 -211 -57 -501 0 -275 16
-398 71 -564 257 -770 1009 -1318 1809 -1320 295 0 499 41 760 155 47 20 113
46 148 57 60 19 62 21 62 54 0 18 -11 58 -25 88 -19 43 -25 71 -25 128 0 91
22 197 54 263 22 44 221 318 280 385 12 14 28 38 34 53 17 40 33 42 93 7 92
-55 104 -53 181 37 37 43 75 87 85 98 10 11 40 46 68 79 l49 58 59 -4 c71 -5
91 -24 84 -80 -5 -41 -39 -93 -122 -188 -59 -68 -115 -157 -115 -183 0 -16
107 -87 132 -87 21 0 92 100 144 203 61 120 129 186 177 171 66 -20 89 -83 62
-165 -17 -52 -119 -212 -176 -277 -16 -18 -29 -42 -29 -52 0 -11 27 -48 60
-82 33 -35 60 -68 60 -74 0 -6 -31 -49 -69 -97 -39 -47 -114 -152 -167 -232
-107 -161 -150 -205 -231 -235 -59 -22 -205 -50 -262 -50 -86 -1 -167 -28
-277 -93 -140 -83 -177 -101 -277 -138 -278 -104 -360 -119 -662 -126 -360 -8
-521 10 -744 83 -638 209 -1182 799 -1356 1470 -45 175 -59 280 -59 459 0 164
19 319 60 502 29 129 78 289 96 310 14 17 38 13 50 -7z m1357 -388 c38 -19 66
-54 75 -93 5 -21 -6 -43 -55 -110 -133 -182 -187 -348 -176 -539 10 -166 59
-291 162 -418 67 -82 82 -126 60 -178 -46 -110 -153 -108 -257 5 -177 191
-276 550 -222 807 38 187 130 387 216 471 76 74 131 89 197 55z m1186 -3 c148
-79 296 -395 312 -665 17 -287 -144 -670 -311 -739 -76 -32 -166 10 -186 86
-11 39 -10 41 45 114 71 95 127 201 157 295 20 65 23 94 22 215 -1 124 -4 149
-28 220 -33 96 -60 145 -139 248 -52 67 -60 83 -60 120 0 103 94 156 188 106z
m-884 -275 c26 -25 29 -34 29 -90 0 -74 -8 -97 -50 -144 -51 -56 -70 -106 -70
-185 0 -83 17 -124 84 -209 25 -33 47 -69 47 -80 6 -74 -15 -137 -50 -156 -35
-18 -102 -12 -147 15 -120 71 -191 263 -182 498 3 102 5 107 51 198 25 52 59
109 74 127 56 65 160 78 214 26z m600 11 c64 -34 142 -159 174 -280 8 -33 15
-95 15 -138 0 -154 -52 -279 -160 -387 -74 -74 -125 -98 -177 -79 -39 13 -53
45 -53 122 0 65 7 81 62 145 45 52 60 99 61 182 1 85 -19 139 -80 224 -59 83
-59 180 0 217 25 16 42 19 81 14 27 -3 61 -12 77 -20z m-284 -287 c47 -32 73
-85 73 -151 0 -170 -233 -238 -320 -94 -41 66 -35 164 13 216 58 63 163 76
234 29z m-2037 -2653 c0 -443 1 -457 50 -500 31 -28 172 -35 219 -10 60 30 61
41 61 493 0 330 3 412 13 421 8 6 17 8 20 4 4 -3 7 -198 7 -432 0 -393 -2
-429 -19 -467 -29 -64 -61 -74 -233 -74 -170 0 -199 10 -236 81 -23 44 -23 45
-20 429 3 429 3 426 71 455 72 32 67 62 67 -400z m1710 179 l0 -224 154 0 154
0 4 183 c3 206 12 237 73 263 70 29 65 60 65 -418 0 -258 -4 -448 -10 -469
-13 -48 -36 -67 -85 -71 l-40 -3 -3 238 -2 237 -155 0 -155 0 0 -176 c0 -180
-10 -252 -39 -277 -9 -8 -35 -18 -58 -22 l-43 -7 1 434 c1 238 4 443 7 456 15
50 89 100 125 84 4 -2 7 -104 7 -228z m883 214 c2 -13 11 -48 20 -78 237 -858
234 -847 247 -855 6 -3 10 -11 10 -17 0 -17 -100 0 -132 23 -39 28 -50 54 -88
207 l-32 132 -123 0 -122 0 -48 -171 c-47 -167 -62 -199 -91 -199 -16 0 -18
12 -4 26 10 10 33 86 109 364 94 345 139 505 151 532 12 25 60 57 86 58 7 0
14 -10 17 -22z m1718 2 c5 -8 45 -96 90 -195 122 -272 169 -376 240 -532 l64
-141 3 433 c2 367 4 435 17 445 12 10 16 9 20 -1 3 -8 4 -228 3 -489 l-3 -475
-45 -3 c-78 -4 -105 18 -157 133 -24 55 -64 143 -88 195 -25 52 -92 199 -149
325 l-106 230 0 -431 c0 -370 -2 -433 -15 -444 -12 -10 -16 -9 -20 2 -3 8 -4
212 -3 454 3 400 5 442 21 467 27 42 110 59 128 27z m-4693 -17 c47 -29 62
-70 62 -171 0 -83 -2 -93 -17 -90 -12 2 -20 17 -25 48 -10 59 -54 128 -105
164 -36 25 -52 29 -101 29 -70 -1 -96 -13 -117 -53 -21 -40 -19 -128 3 -167
25 -45 64 -74 179 -132 116 -58 165 -96 196 -155 19 -35 22 -57 22 -151 0 -97
-3 -116 -25 -160 -46 -92 -100 -109 -317 -103 l-122 3 -36 35 c-46 45 -55 80
-55 223 0 108 1 117 19 117 16 0 19 -10 23 -80 6 -102 43 -180 107 -225 39
-28 50 -30 125 -30 119 0 146 31 146 167 0 114 -18 137 -168 217 -212 113
-247 158 -246 316 0 102 18 154 67 189 24 18 48 22 165 26 155 6 187 4 220
-17z m4377 -27 c4 -22 5 -84 3 -136 -2 -78 -6 -95 -19 -98 -14 -3 -17 11 -21
85 -4 91 -20 133 -56 149 -9 4 -79 9 -154 12 l-138 4 0 -211 0 -211 25 0 c69
0 140 62 151 131 5 27 12 39 23 39 14 0 16 -20 14 -181 -1 -100 -5 -184 -8
-187 -9 -9 -21 10 -33 55 -14 47 -70 103 -105 103 -12 0 -32 3 -44 6 l-23 6 0
-222 0 -222 113 3 c111 4 114 5 155 38 63 50 94 118 100 219 4 72 7 82 23 82
18 0 19 -9 19 -125 0 -143 -14 -192 -64 -228 -30 -21 -39 -22 -256 -22 l-225
0 -3 455 c-1 250 0 465 3 477 5 23 7 23 238 25 127 2 242 1 254 -1 17 -2 23
-12 28 -45z m-2995 28 c53 -32 65 -66 68 -209 3 -121 2 -133 -21 -177 l-24
-46 24 -13 c53 -28 58 -50 58 -236 0 -158 -2 -174 -21 -200 -43 -59 -66 -63
-314 -61 l-225 3 -3 455 c-1 250 0 465 3 478 5 22 6 22 216 22 177 0 215 -3
239 -16z m1917 -4 c45 -27 56 -54 96 -230 103 -452 122 -529 127 -524 3 3 48
177 100 387 l95 382 29 3 c26 3 27 2 14 -12 -9 -8 -64 -214 -129 -476 -62
-253 -116 -466 -120 -472 -11 -18 -58 1 -80 32 -20 27 -30 64 -109 400 -62
261 -110 459 -116 482 -3 10 -12 25 -20 33 -14 13 -10 15 33 15 30 0 60 -7 80
-20z"/>
<path d="M6112 3678 c-29 -106 -53 -197 -52 -201 0 -13 210 -6 210 7 0 21 -95
386 -101 386 -3 0 -29 -87 -57 -192z"/>
<path d="M4350 3779 l0 -201 96 4 c94 3 96 4 125 36 29 32 29 33 29 158 0 187
-13 204 -159 204 l-91 0 0 -201z"/>
<path d="M4350 3321 l0 -221 99 0 c170 0 192 19 199 171 6 131 -4 203 -33 237
-23 26 -27 27 -144 30 l-121 4 0 -221z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
